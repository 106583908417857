.title-confirm-appointment {
  display: flex;
  margin-top: 9%;
  margin-bottom: 2rem;
  align-items: center;
}

.left-margin-mobile {
  padding-left: 2rem;
  padding-top: 2rem;
}

@media (max-width: 440px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */

  .paymentButtons {
    display: grid;
    justify-content: space-around;
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .buttonsDiv {
    margin-left: 4rem !important;
    padding-top: 3rem;
  }

  .cardDiv {
    display: none !important;
  }

  .infoConsultaDiv {
    display: none;
  }

  .mainContainerDiv {
    width: 100% !important;
  }

  .box-report-appointment {
    background: #fafafb;
    overflow: visible;
    width: 100%;
    border-radius: 10px;

    padding-top: 1rem;
    padding-left: 0rem;
    padding-right: 2rem;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 0rem;
    margin-left: 0rem;
    height: 74vh;
  }
}

@media (max-width: 990px) {
  /* smartphones, Android phones, landscape iPhone */
  /* .paymentButtons{
    display: grid;
    justify-content: space-around;
    margin-top: 2rem;
    margin-left: 0rem;
} */

  .left-margin-mobile {
    padding-left: 5rem !important;
    padding-top: 2rem;
  }

  .buttonsDiv {
    margin-left: 2rem;
    padding-top: 2rem;
  }

  .cardDiv {
    display: none !important;
  }

  .infoConsultaDiv {
    display: none;
  }

  .mainContainer {
    /* overflow-y: scroll; */
  }

  .mainContainerDiv {
    width: 100% !important;
    /* overflow-y: scroll; */
  }

  .box-report-appointment {
    background: #fafafb;
    overflow: visible;
    width: 100%;
    border-radius: 10px;
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    justify-content: space-between;
    /* min-height: 84%; */
    margin-left: 0rem;
    margin-left: 0rem;
    /* height: 74vh; */
  }

  .title-confirm-appointment {
    margin-left: 2rem;
  }

  .col-btn-payment {
    display: flex !important;
  }

  .payment-form-content {
    height: 68vh;
  }
}

@media (min-width: 991px) {
  .payment-form-content {
    height: 46vh;
  }
}

@media (max-width: 1024px) {
  .box-report-appointment {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .title-confirm-appointment {
    margin-left: 1rem;
  }
}

@media screen and (min-width: 1320px) {
  .buttonsDiv {
    margin-left: 2rem;
  }

  .paymentButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    margin-left: 0rem;
  }

  .buttonsDiv {
    margin-left: 2rem;
  }

  #cardDiv {
    display: block;
  }

  .payButtons {
    padding-right: 3rem;
    justify-content: flex-end;
    display: flex;
  }

  .tarjeta {
    margin-left: auto;
    margin-right: 2rem;
    margin-top: 2rem;
  }

  .rccs__card {
    height: 209px !important;
    margin: 0 auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 395px !important;
    display: flex;
    margin-right: 3rem !important;
    margin-top: 2rem !important;
  }
}

/* On screens that are 600px or less, set the background color to olive */

@media screen and (min-width: 1820px) {
  .buttonsDiv {
    margin-left: 2rem;
  }

  .paymentButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    margin-left: 0rem;
  }

  .buttonsDiv {
    margin-left: 2rem;
  }

  #cardDiv {
    display: block;
  }

  .box-report-appointment {
    background: #fafafb;
    overflow: hidden;
    width: 88%;
    border-radius: 10px;
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    flex-direction: column;
    justify-content: space-between;
    /* min-height: 84%; */
    margin-left: 4rem;
    position: relative;
    height: 74vh;
  }

  .payButtons {
    padding-top: 52px;
    padding-right: 4.9rem;
    justify-content: flex-end;
    display: flex;
  }

  .rccs__card {
    height: 209px !important;
    margin: 0 auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 395px !important;
    display: flex;
    margin-right: 4.7rem !important;
    margin-top: 2rem !important;
  }

  /* .rccs {
      margin: 0 auto;
      -webkit-perspective: 1000px;
      perspective: 1000px;
      width: 293px;
      margin-left: 0rem!important;
    } */

  .tarjeta {
    margin-right: 1rem;
    margin-top: 2rem;
    margin-left: auto;
  }
}

@media screen and (min-width: 1283px) {
  .buttonsDiv {
    margin-left: 2rem;
  }

  .paymentButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    margin-left: 0rem;
  }

  .buttonsDiv {
    margin-left: 2rem;
  }

  #cardDiv {
    display: block;
  }

  .box-report-appointment {
    background: #fcfcfd;
    border-radius: 10px;
    padding: 2rem;
  }

  .payButtons {
    padding-top: 30px;
    padding-right: 0.9rem;
    justify-content: flex-end;
    display: flex;
  }

  .rccs__card {
    height: 209px !important;
    margin: 0 auto;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 347px !important;
    display: flex;
    margin-right: 0.7rem !important;
    margin-top: 2rem !important;
  }

  /* .rccs {
      margin: 0 auto;
      -webkit-perspective: 1000px;
      perspective: 1000px;
      width: 293px;
      margin-left: 0rem!important;
    } */

  .tarjeta {
    margin-right: 1rem;
    margin-top: 2rem;
    margin-left: auto;
  }
}

/* @media screen and (min-width: 1820px) {
    .payButtons{
     padding-top: 52px;
     padding-right: 25px;
    justify-content: flex-end;
    display: flex;
    }

    .tarjeta {
        width: 340px;
        height: 224px;
        /* padding: 2rem; */
/* margin-left: 5rem;
        margin-right: 1rem;
        margin-top: 2rem;
    }
  } */

.btn-metodo-pago {
  max-width: 300px !important;
  height: 50px !important;
  border-radius: 8px !important;
}

.btn-metodo-pago:hover {
  border: 1px solid #d9d9d9 !important;
  font-weight: bold;
  opacity: 60%;
  color: rgba(0, 0, 0, 0.85) !important;
}

.btn-metodo-pago:focus {
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.title-lateral {
  font-weight: 600;
  font-size: 1.2rem;
  display: grid;
  justify-content: left;
  margin-left: 1.5rem;
  margin-top: 7.5rem;
  /* margin-bottom: 1rem; */
}

.title-lateral-light {
  font-weight: 400;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.35);
}

.title-lateral-bold {
  font-weight: 400;
  font-size: 1rem;
}

.rccs {
  margin: 0 auto;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 100% !important;
  /* margin-right: 1rem; */
}

.tarjeta__number {
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 1rem;
}

.tarjeta__label {
  margin: 0;
  font-size: 0.6rem;
  font-weight: 400;
}

.card__month {
  font-weight: 600;
  font-size: 0.8rem;
}

.card__año {
  font-weight: 600;
  font-size: 0.8rem;
}

.tarjeta__details-name {
  font-weight: 600;
  font-size: 0.8rem;
}

.tarjeta__details {
  display: flex;
  justify-content: space-between;
}

.card-form__input {
  height: 28px;
  border-radius: 50px !important;
}

.card-form__label {
  font-weight: 400;
  font-size: 1rem !important;
  display: flex;
  margin-bottom: 1rem;
  color: #000000;
}

.btn-prev-index {
  border: 1px solid #4177c6 !important;
  color: #4177c6 !important;
  border-radius: 50px !important;
  width: 151px !important;
  height: 40px !important;
  margin-right: 1rem !important;
  font-size: 1rem !important;
}

.btn-prev-index:hover {
  font-weight: bold;
  opacity: 80%;
}

.btn-prev-index:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

/* ................. */
.btn-next-index {
  background: #006aff !important;
  border-radius: 50px !important;
  width: 151px !important;
  height: 40px !important;
  font-size: 1rem !important;
  color: white !important;
}

.btn-next-index:hover {
  font-weight: bold;
  opacity: 80%;
}

.button-30 {
  background: #006aff !important;
  border-radius: 50px !important;
  width: 151px !important;
  height: 40px !important;
  font-size: 0.7rem !important;
  color: white !important;

  align-items: center;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;

  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  transition: all 0.3s ease;

  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.5s, transform 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
}

.button-30:focus {
  box-shadow: #000000 0 0 0 10px inset, rgba(45, 35, 66, 0.4) 0 2px 10px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 5s;
  transform: translateY(-3px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}
